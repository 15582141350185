$(document).ready(function () {

  runKvSlider();
	runOpinionsSlider();
	runRecentWork();
	runAnyIndustrySlider();
	runMustBeAchievedSlider();
	runWorkerWithCarousel();
	runCustomScrollbar01();
	runCustomScrollbar02();
	runCustomScrollbar03();
	runMobileMenu();
	runAnotherJS();
	runAnimation();

});

function runKvSlider() {
	if (document.querySelector('.slider-kv')) {
		$('.slider-kv').cycle ({
			timeout: 6000,
			speed: 800,
			pauseOnHover: false,
			slides: '> .slide',
			pager: '> ul.dots',
			pagerTemplate: '<li></li>',
			pagerActiveClass: 'active',
			swipe: true
		});
	}
}

function runOpinionsSlider() {
	if (document.querySelector('.slider-opinions')) {
		$('.slider-opinions').cycle ({
			timeout: 0,
			speed: 800,
			pauseOnHover: false,
			slides: '> .slide',
			prev: '.opinions .arrows .prev',
			next: '.opinions .arrows .next',
			swipe: true
		});
	}
}

function runRecentWork() {
	if (document.querySelector('.recent-work')) {
		$('.slider-recent').cycle ({
			timeout: 0,
			speed: 800,
			pauseOnHover: false,
			slides: '> .slide',
			prev: '> .arrows .prev',
			next: '> .arrows .next',
			swipe: true
		});
		$('.recent-work ul li').on('click', function() {
			var slideId = $(this).attr('data-slide');
			$('.slider-recent').cycle('goto', slideId);
			$(this).parent().stop(true,true).fadeToggle(500);
			$('.recent-work .slider-recent').stop(true,true).delay(400).fadeToggle(400);
		});
		$('.recent-work .slider-recent .close').on('click', function() {
			$(this).parent().stop(true,true).fadeToggle(400);
			$('.recent-work ul').stop(true,true).delay(400).fadeToggle(400);
		});
	}
}

function runAnyIndustrySlider() {
	if (document.querySelector('.slider-any-industry')) {
		$('.slider-any-industry').cycle ({
			timeout: 0,
			speed: 800,
			pauseOnHover: false,
			slides: '> .slide',
			prev: '.any-industry .arrows .prev',
			next: '.any-industry .arrows .next',
			swipe: true
		});
	}
}

function runMustBeAchievedSlider() {
	if (document.querySelector('.slider-must-be-achieved')) {
		$('.slider-must-be-achieved').cycle ({
			timeout: 0,
			speed: 800,
			pauseOnHover: false,
			slides: '> .slide',
			prev: '.must-be-achieved .arrows .prev',
			next: '.must-be-achieved .arrows .next',
			swipe: true
		});
	}
}

function runWorkerWithCarousel() {
	if (document.querySelector('.worked-with-carousel')) {
		$('.worked-with-carousel').owlCarousel({
			autoplay: true,
			autoplaySpeed: 500,
			autoplayTimeout: 3000,
			loop: true,
			items: 4,
			margin: 0,
			responsive: {
				1024: {
					items: 4
				},
				768: {
					items: 3
				},
				560: {
					items: 2
				},
				0: {
					items: 1
				}
			}
		});
	}
}

function runCustomScrollbar01() {
	if (document.querySelector('.opinions .slider-opinions .slide .opinion-container')) {
		$('.opinions .slider-opinions .slide .opinion-container').mCustomScrollbar({
			setHeight: 300,
		});
	}
}

function runCustomScrollbar02() {
	if (document.querySelector('.any-industry .slider-any-industry .slide .inner')) {
		$('.any-industry .slider-any-industry .slide .inner').mCustomScrollbar({
			setHeight: 320,
		});
	}
}

function runCustomScrollbar03() {
	if (document.querySelector('.must-be-achieved .slider-must-be-achieved .slide .inner')) {
		$('.must-be-achieved .slider-must-be-achieved .slide .inner').mCustomScrollbar({
			setHeight: 190,
		});
	}
}

function runMobileMenu() {
	$('.header__trigger').on('click', function() {
		$('.header__trigger').toggleClass('menu-visible');
		$('.header__mobile-top').slideToggle();
	});
}

function runAnotherJS() {
	$('.must-be-achieved .slider-must-be-achieved .slide .inner p').addClass('medium');
	$('.any-industry .slider-any-industry .slide .inner p').addClass('medium');
}

function runAnimation() {
	function onScrollInit(items, trigger) {
    items.each(function () {
      var osElement = $(this),
      osAnimationClass = osElement.attr('data-os-animation'),
      osAnimationDelay = osElement.attr('data-os-animation-delay');
      osElement.css({
        '-webkit-animation-delay': osAnimationDelay,
        '-moz-animation-delay': osAnimationDelay,
        'animation-delay': osAnimationDelay
      });
      var osTrigger = (trigger) ? trigger : osElement;
      osTrigger.waypoint(function () {
        osElement.addClass('animated').addClass(osAnimationClass);
      }, {
        triggerOnce: true,
        offset: '90%'
      });
    });
  }
	onScrollInit($('.os-animation'));
  onScrollInit($('.staggered-animation'), $('.staggered-animation-container'));
}
